import React, { useEffect } from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';
import SignUpFollow from './SignUpFollow';
import Dashboard from './Dashboard';
import Quotes from './Quotes';
import Home from './Home';
import Status from './Status';
import QuickCreate from './QuickCreate';
import CreateProviderQuote from './CreateProviderQuote';
import CreateUserQuote from './CreateUserQuote';
import Invoices from './Invoices';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-R3Z3GHXJ1E');

const usePageTracking = () => {

};

export default function App() {
  useEffect(() => {
    ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);

  return (
	<Router>
	    <Routes>
		<Route path="/" element={<SignIn/>} />
      		<Route path="/signin" element={<SignIn/>} />
      		<Route path="/signup" element={<SignUp/>} />
      		<Route path="/signup-follow" element={<SignUpFollow/>} />
      		<Route path="/dashboard" element={<Dashboard/>} />
      		<Route path="/quotes" element={<Quotes/>} />
      		<Route path="/home" element={<Home/>} />
      		<Route path="/status" element={<Status/>} />
      		<Route path="/quick-create" element={<QuickCreate/>} />
      		<Route path="/provider/quote" element={<CreateProviderQuote/>} />
      		<Route path="/user/quote" element={<CreateUserQuote/>} />
      		<Route path="/invoices" element={<Invoices/>} />
	    </Routes>
	</Router>
  );
}
